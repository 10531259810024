































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class SiteContact extends Vue {
  private email = null
  private phone = null

  private created() {
    this.$axios
      .get('/v3/support/contact/get_initial_data')
      .then((response) => {
        this.email = response.data.data.email
        this.phone = response.data.data.phone
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, _i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
      .finally(() => {
        // noop
      })
  }
}
